/* FIXES
--------------------------------------------------------------------------------------------*/
.ac_menu-2_bar_bottom {
    background-color: $brand-primary;
    a {
        color: #fff;
        font-weight: bold;
    }
}

.ac_menu-2_logo_img {
    height: 70px;
    max-width: 200%;
    width:auto;
    margin-left:-45px;
}

.ac_menu-2_sticky.is-stuck .ac_menu-2_logo_img {
    width: 200px;
    height: auto;
}

.ac_menu-2_bar_bottom {
background-color: $brand-tertiary;
}

[data-s-type="hero-alt"] {
    background-color: $brand-secondary;
}

[data-f-type="footer-1"] .ac_footer_primary {
    background-color:$brand-tertiary-darker;
}
.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
    border-color:$brand-primary transparent transparent;
}

[data-s-type="hero"] .ac_hero_container {
    height: 65vh;
}

[data-m-type="menu-2"] ul.is-dropdown-submenu li.is-submenu-item {
    a {
        color:$brand-tertiary;
        font-weight: normal;
    }
}

.ac_menu-2_main_content {
    a {
        font-size: 20px;
        font-weight: bold;
    }
}

[data-s-type="services"] .ac_item_content {
    border: 2px solid #fff;
    border-radius: 10px;
}

[data-s-id="home-services"] {
    padding-top:20px;
    padding-bottom: 10px;
}
.ac_toptasks_item_content_description {
    color:#fff;
}

.ac_toptasks_item_content_title {
    color:#fff;
}

.ac_toptasks_item_icon {
    background-color: $brand-secondary;
}

[data-s-type="services"] .ac_item_content {
    background-color: $brand-tertiary;
}

[data-s-type="services"] .ac_item:hover {
    background-color: none !important; 
    text-decoration: none !important;
}
[data-s-type="services"] .ac_item_content:hover {
    background-color: $brand-tertiary-darker !important;
}


.home .ac_content_1-3 {
    background: $brand-lightest-gray;
    padding:40px;
    border-radius: 10px;
}

@media screen and (max-width: 50em){
    .home .ac_content_1-3 {
        display: none;
    }
}


[data-f-type="footer-1"] .ac_socials {
    display: block;
}

@media screen and (max-width:1150px){
    .ac_menu-2_logo_img {
        height: 70px;
        max-width: 200%;
        width:auto;
        margin-left:10px;
    }
}

@media screen and (max-width:800px){
    .ac_socials_link {
        margin-left:45%;
    }
}

.ac_heading_container {
    padding:0 10px;
}

input[type="radio" i] {
    padding:0 !important;
    margin:0 !important;
}

.gform_wrapper ul.gfield_checkbox li, .gform_wrapper ul.gfield_radio li {
    padding:0 !important;
    margin:0 !important;
    margin-right:10px !important;
    display:inline;
}

.ac_content_1-1 {
    padding-left:10px;
}

.ac_heading_title {
    padding-left:10px;
}

#menu-mobile {
    padding-top:20px;
}
@media screen and (max-width: 880px) {
    [data-m-type="menu-2"] + .main-container {
        padding-top: 90px;
    }

    .home [data-m-type="menu-2"] + .main-container {
        padding-top: 70px;
    }

    [data-s-type="hero"] {
        height: 240px;
    }

    
    
}
@media screen and (max-width: 500px) {
    .ac_menu-2_mobile_btn {
        display:none;
    }
}

h2 {
    margin-bottom: 28px;
}

[data-s-type="image-gallery_full-screen"] .slick-next svg, [data-s-type="image-gallery_full-screen"] .slick-prev svg {
    fill:$brand-primary;
}

.menu-mobile-container ul li {
    font-weight: 600;
    border-bottom: 3px solid white;
}

.is-accordion-submenu-parent > a::after {
    right: -2rem;
}

.menu-mobile-container ul {
    list-style-type: none;
}


.ac_socials_link .ac_socials{ 
    margin-left:0px !important;
    justify-content: center !important;
}

@media screen and (max-width: 39.9375em) {
    .ac_toptasks_item_icon {
        margin-top: 90px;
    }

    .ac_toptasks_item_container {
        background-color: $brand-tertiary-darker;
    }

    [data-s-type="toptasks"] {
        padding-top:0px !important;
        margin-top:10px !important;
    }

    .ac_toptasks_container {
    margin:0 !important;
    }
}

.main-container [data-s-type="section-content"] a {
    text-decoration: underline;
    color: $brand-primary;
}
